import React, { Suspense } from "react";
import { Provider, useDispatch } from "react-redux";
import { persistor, store } from "src/redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import "./i18n";
import Loading from "./components/Loading";

const TheContent = React.lazy(() => import("./components/TheContent"));

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <TheContent />
        </PersistGate>
      </Provider>
    </Suspense>
  );
}

export default App;
