import { createSlice } from "@reduxjs/toolkit";
export const couponSlice = createSlice({
  name: "coupon",
  initialState: {
    coupon2: false,
    coupon3: false,
  },
  reducers: {
    setCoupon2: (state, action) => {
      state.coupon2 = action.payload;
    },
    setCoupon3: (state, action) => {
      state.coupon3 = action.payload;
    },
  },
});

export const { setCoupon2, setCoupon3 } = couponSlice.actions;
export const getCoupon2 = (state) => state.coupon.coupon2;
export const getCoupon3 = (state) => state.coupon.coupon3;

export default couponSlice.reducer;
