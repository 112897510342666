import {
  CheckCircleOutlined,
  SyncOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import jwt from "jwt-encode";

export const getStorages = async (key) => {
  const BRAND = "jaosua999";
  const JWT_SECRET = BRAND.split("").reverse().join("").toLocaleUpperCase();
  const token = await jwt({ iat: 1516239022 }, JWT_SECRET, {
    algorithm: "HS256",
  });
  sessionStorage.setItem("key", token);
  return sessionStorage.getItem(key);
};

export const getStorage = (key) => {
  return sessionStorage.getItem(key);
};

export const getLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const setlocalStorage = (key, value) => {
  return localStorage.setItem(key, value);
};

export const getTag = (status, t) => {
  switch (status) {
    case "success":
      return {
        icon: <CheckCircleOutlined />,
        name: `${t("txt_success_r")}`,
        color: "#28a745",
      };
    case "process":
      return {
        icon: <SyncOutlined spin />,
        name: "ดำเนินการ",
        color: "#ffc107",
      };
    case "failed":
      return {
        icon: <ExclamationCircleOutlined />,
        name: "ไม่สำเร็จ",
        color: "#dc3545",
      };
    case "withdraw_failed":
      return { name: "ไม่สำเร็จ", color: "magenta" };
    case "withdraw_cancel":
      return { name: `${t("cancel")}`, color: "#dc3545" };
    case "withdraw_success":
      return { name: `${t("txt_success_r")}`, color: "#28a745" };
    case "withdraw_waiting":
      return { name: "รอดำเนินการ", color: "gold" };
    default:
      return { name: "-", color: "" };
  }
};

export const getTagLotto = (status, t) => {
  switch (status) {
    case "success":
      return { name: "ออกผลแล้ว", color: "#28a745" };
    case "cancel":
      return { name: "ยกเลิก", color: "#ff4d3b" };
    case "pending":
      return { name: "รอออกผล", color: "#ffc107" };
    default:
      return { name: "-", color: "" };
  }
};

// ====== LOCAL STORAGE ======
export const getStoraged = (key) => {
  return localStorage.getItem(key);
};

export const setStorage = (key, value) => {
  return localStorage.setItem(key, value);
};
// ===========================

// export const userRefresh = () => {
//   const obj = localStorage.getItem("userId");
//   if (obj) {
//     StaffService.getUser(obj)
//       .then(({ data }) => {
//         setStorage(
//           "permissions_ignore",
//           JSON.stringify(data.permission.permissions)
//         );
//       })
//       .catch((err) => {});
//   }
//   return obj;
// };

// export const getThemeModeValue = () => {
//   return getStoraged("theme_mode") ? getStoraged("theme_mode") === "true" : true;
// };
